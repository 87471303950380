import {trackSignUpClick} from 'utils/analytics';

const GetStartedFooterSection = ({
  text,
  buttonText,
  href,
}: {
  text?: string;
  buttonText?: string;
  href?: string;
}) => (
  <section className="bg-[#FAFBFC]">
    <div className="container mx-auto flex w-full flex-col justify-center gap-12 py-12 px-4 text-white md:px-8 lg:flex-row lg:py-32">
      <div className="flex flex-col items-center gap-5 rounded-md bg-[#E6E9E6]/50 bg-gradient-to-b from-[#4c68f633] via-[#ffedef33] to-[#a7ead533] px-5 py-12 lg:min-w-[960px] lg:py-16">
        <p className="lg:text-4.5xl text-2.5xl !mb-0 font-semibold text-gray-800">Get Started</p>
        <p className="!mb-0 text-center font-sans text-sm text-gray-700 md:max-w-[470px] lg:max-w-[572px] lg:text-base">
          {text ||
            'Empower your development team to optimize code, save infrastructure costs, and boost application performance.'}
        </p>
        <a
          href={href || 'https://cloud.polarsignals.com/signup'}
          className="w-fit rounded bg-gray-800 px-10 py-3 font-sans text-base font-medium text-white first-line:text-center hover:opacity-90 lg:py-4 lg:text-lg"
          onClick={() => trackSignUpClick('footer-trial-cta')}
        >
          {buttonText || 'Start free 14-day trial'}
        </a>
      </div>
    </div>
  </section>
);

export default GetStartedFooterSection;
